/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  background: black;
}

#fixed-space {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensures the container fills the viewport */
}

/* Styles for StarField */
#space {
  position: absolute;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.star {
  position: absolute;
  border-radius: 50%;
  background-image: radial-gradient(circle, rgba(255,255,255,0.8), rgba(255,255,255,0) 70%);
  box-shadow: 0 0 8px rgba(255,255,255,0.8); /* Glowing effect */
  animation: flyThroughSpace 30s linear infinite;
  background-color: transparent; /* Ensure the background is transparent */
}

@keyframes flyThroughSpace {
  0% {
      transform: translateZ(0);
  }
  50% {
      transform: translateZ(-100px);
  }
  0% {
    transform: translateZ(0);
}
}

.fixed-star {
  position: absolute;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.8);  /* Bright white with opacity */
  width: 2px;  /* Default size */
  height: 2px;  /* Default size */
  box-shadow: 0 0 12px hsla(0, 0%, 100%, 0.8);  /* Bright glowing effect */
  animation: flyThroughSpace2 10s linear infinite;
}

@keyframes flyThroughSpace2 {
  0% {
      transform: translateZ(-100px);
  }
  50% { 
      transform: translateZ(0);
  }
  0% {
    transform: translateZ(-100px);
}
}

@keyframes twinkle {
  0% { 
      opacity: 0;
      transform: scale(0.8);
  }
  50% {
      opacity: 1;
      transform: scale(1.5); /* More noticeable change in size */
  }
  100% {
      opacity: 0;
      transform: scale(0.8);
  }
}

/* Styles for WelcomeBorder */
.welcome-border {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid white;
  border-radius: 5px;
  background-color: black;
  padding: 20px;
  box-sizing: border-box;
  min-width: 700px; /* Set a reasonable minimum width */
  min-height: 600px; /* Set a reasonable minimum height */
  z-index: 5;
  border-color: #39FF14; /* Neon green */
  box-shadow: 0 0 10px #39FF14; /* Adds a glowing effect around the border */
}

.welcome-border:hover {
  cursor: pointer;
}

.welcome-message {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4em;
  color: white;
  font-family: 'Impact', 'Arial Black', sans-serif;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  z-index: 10;
}

.welcome-message p {
  margin: 0 0 30px 0; /* Top Right Bottom Left */
  color: white;
  font-size: 1em;
  font-family: 'Impact', 'Arial Black', sans-serif;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  z-index: 10;
}


.welcome-border:hover .welcome-message p {
  color: #ff4d4d; /* Neon green */
}


.centered-image {
  display: block;
  position: absolute;
  top: calc(50% - 150px); /* Adjust this value based on the desired distance from the 'Welcome' text */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px; /* Adjust based on your image size */
  height: auto; /* Maintains the aspect ratio of the image */
  z-index: 10; /* Make sure it's not hidden behind other elements */
  border-radius: 10px; /* Adds rounded corners, adjust radius as needed */
}
